import React from "react"
import styles from "./footer.module.css"
import { Link } from "gatsby"

const Footer = () => {
  const textStripContent = [
    <div className={styles.textStripItem} key="1">
      Pub? Pub! Pub? Pub! Pub? Pub! Pub? Pub!
    </div>,
  ]

  const textStripContainer = (
    <div className={styles.textStripContainer}>
      {textStripContent} {textStripContent}
    </div>
  )
  return (
    <div className={styles.wrapper}>
      <div className={styles.textStrip}>
        <div className={styles.textStripSlider}>
          {textStripContainer}
          {textStripContainer}
        </div>
      </div>
      <footer className={styles.footer}>
        {/* <div className={styles.container}>
          <Link to="/" className={styles.logo}>
            <span>Shop</span>
            <span>ped</span>
          </Link>
          <div className={styles.links}>
            <div>
              <Link to="/">Directory</Link>
              <Link to="/build">Build</Link>
            </div>
            <div>
              <a href="https://www.instagram.com/shoppedundefined/">
                Instagram
              </a>
              <Link to="/privacy">Privacy Policy</Link>
            </div>
          </div>
        </div> */}
        <div className={styles.containerBottom}>
          Got some ideas on how to make this site better?{" "}
          <a href="mailto:hello@thisisundefined.com"> Reach out</a> <br></br>
          Proudly brought to you with a 🍺 by{" "}
          <a href="https://www.thisisundefined.com/" target="_blank">
            Undefined
          </a>{" "}
          - © {new Date().getFullYear()}
        </div>
      </footer>
    </div>
  )
}

export default Footer

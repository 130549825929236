import { Link } from "gatsby"
import React from "react"
import styles from "./header.module.css"

const Header = () => (
  <header className={styles.header}>
    <div className={styles.container}>
      <Link to="/" className={styles.logo}>
        <span>Outside</span>
        <span>☀️Drinks</span>
      </Link>
      <div className={styles.headerLinks}>
        <Link to="/submit" activeClassName={styles.active}>
          Submit a pub
        </Link>
        {/* <Link to="/share" activeClassName={styles.active}>
          Share
        </Link> */}
      </div>
    </div>
  </header>
)

export default Header
